import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './shared';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './material/material.module';
import { AppConfig } from './config/app.config';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './services/authentication.service';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { AuthInterceptor } from './services/auth.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ReliabilityService } from './services/reliability.service';
import { AppSense } from './shared/ui/app-sense'; //JCG 7/12/24
//JCG 10/31/24
import { MatSnackBarModule } from '@angular/material/snack-bar';




@NgModule({
  declarations: [
    AppComponent
     ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    MatSnackBarModule, //JCG 10/31/24
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory())
  ],
  providers: [AuthGuard, 
              ReliabilityService,
              AppConfig,
              AppSense, //JCG 7/12/24
              {
                provide: APP_INITIALIZER, 
                useFactory: getUseFactory, 
                deps: [AppConfig], 
                multi: true
              },
              {
                //JCG - 4/17/2024
                provide: HTTP_INTERCEPTORS, 
                useClass: LoadingInterceptor, 
                multi: true
              },
              {
                //Modifies request header by swapping the access token with ID token.
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor, //JCG 9/4/24
                multi: true
              },
              {
                //MSAL Token Silent-Refresh
                provide: HTTP_INTERCEPTORS,
                useClass: MsalInterceptor,
                multi: true
              }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getUseFactory(config: AppConfig) {
  return () => config.load();
}